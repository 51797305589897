import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';

class ItemTypeFilterItem extends Component {
	constructor(props) {
		super();
		this.props = props;
	}
	handleEvent(e) {
		const query = qr.props.query;
		query.itemTypes = query.itemTypes.filter(itemType => itemType !== this.props.id);
		if (!this.props.active) {
			query.itemTypes.push(this.props.id);
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	render() {
		if (parseInt(this.props.count, 10) > 0) {
			return this.html`
			<li class="wzb-filter__item">
				<label class="wzb-checkbox">
					<input class="wzb-checkbox__checkbox" onChange="${this}" ?checked=${this.props.active} type="checkbox" name="checkbox" value="${this.props.id}">
					<span class="wzb-checkbox__label">${this.props.de}</span>
					<span class="wzb-checkbox__on"><span data-type="svg"><canvas width="15" height="15"></canvas><svg viewBox="0 0 15 15"  role="img"><use xlink:href="#circle-checked"></use></svg></span></span>
					<span class="wzb-checkbox__off"><span data-type="svg"><canvas width="15" height="15"></canvas><svg viewBox="0 0 15 15" role="img"><use xlink:href="#circle-unchecked"></use></svg></span></span>
				</label>
			</li>
		`;
		} else {
			return this.html``;
		}
	}

}

export default ItemTypeFilterItem;
