import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import {zt} from '../store/ZoteroState';

class SpecialFilterItem extends Component {
	constructor(props) {
		super();
		this.props = props;
		zt.subscribe((pr) => {
			this.render();
		});
		qr.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		const query = qr.props.query;
		const id = this.getIdOfTag(this.props.tag);
		const active = this.isActive();
		query.tags = []; //query.tags.filter(tag => tag !== id);
		if (!active) {
			query.tags.push(id);
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	getIdOfTag(tagName) {
		const tag = zt.props.tags.find(t => t.tag === tagName);
		if (tag) {
			return tag.id;
		}
		return null;
	}
	isActive() {
		return qr.props.query.tags && qr.props.query.tags.includes(this.getIdOfTag(this.props.tag));
	}
	render() {
		return this.html`
			<div class="wzb-filter__item">
				<label class="wzb-checkbox">
					<input class="wzb-checkbox__checkbox" onChange="${this}" ?checked=${this.isActive()} type="checkbox" name="checkbox" value="${this.getIdOfTag(this.props.tag)}">
					<span class="wzb-checkbox__label">${this.props.label}</span>
					<span class="wzb-checkbox__on"><span data-type="svg"><canvas width="15" height="15"></canvas><svg viewBox="0 0 15 15"  role="img"><use xlink:href="#circle-checked"></use></svg></span></span>
					<span class="wzb-checkbox__off"><span data-type="svg"><canvas width="15" height="15"></canvas><svg viewBox="0 0 15 15" role="img"><use xlink:href="#circle-unchecked"></use></svg></span></span>
				</label>
			</li>
		`;
	}

}

export default SpecialFilterItem;
