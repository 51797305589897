import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import {ux} from '../store/UxState';

class SearchCreator extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.timer = null;
		qr.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		const value = e.target.value;
		if (e.keyCode === 13) {
			e.stopPropagation();
			e.preventDefault();
			this.applySearch(value);
		} else {
			this.debounce(value);
		}
	}
	debounce(value) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => this.applySearch(value), 500);
	}
	applySearch(value) {
		const query = qr.props.query;
		query.searchCreator = value;
		query.page = 1;
		qr.props.setQuery(query);
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('searchCreator');
	}

	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
		<form class="wzb-filters__filterSearch">
			<label class="wzb-inputField" for="search">
				<input class="wzb-inputField__input wzb-inputField__input--filterSearch" onKeyDown="${this}" onKeyUp="${this}" id="searchCreator" name="searchCreator" type="text" placeholder="Person" value="${qr.props.query.searchCreator}">
				<span class="wzb-inputField__icon">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29"><title>magnifying-glass-big</title><rect x="2" y="10" width="3" height="3" fill="#1d1d1b"></rect><rect x="24" y="24" width="3" height="3" fill="#1d1d1b"></rect><rect x="20" y="20" width="3" height="3" fill="#1d1d1b"></rect><rect x="16" y="16" width="3" height="3" fill="#1d1d1b"></rect><rect x="10" y="18" width="3" height="3" fill="#1d1d1b"></rect><rect x="16" y="4" width="3" height="3" fill="#1d1d1b"></rect><rect x="18" y="10" width="3" height="3" fill="#1d1d1b"></rect><rect x="4" y="4" width="3" height="3" fill="#1d1d1b"></rect><rect x="4" y="16" width="3" height="3" fill="#1d1d1b"></rect><rect x="10" y="2" width="3" height="3" fill="#1d1d1b"></rect></svg>
				</span>
			</label>
		</form>
		`;
	}

}

export default SearchCreator;
