import {Component} from 'hyperhtml';
import config from '../config';
import TagFilterGroup from './tagFilterGroup';
import {ux} from '../store/UxState';

class ResearchGroups extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.researchGroupMap = config.researchGroupMap;
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('tags');
	}
	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
				<div class="wzb-filters__filter wzb-filter wzb-filter--publications" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat2">
		<div class="wzb-filter__head" data-wzb-filter-head="">
			<span class="wzb-filter__arrow"><svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 13"><rect x="9" y="9" width="3" height="3" transform="translate(0 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="5" y="5" width="3" height="3" transform="translate(0 13) rotate(-90)" fill="#1d1d1b"></rect><rect x="13" y="5" width="3" height="3" transform="translate(8 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="17" y="1" width="3" height="3" transform="translate(16 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="1" y="1" width="3" height="3" transform="translate(0 5) rotate(-90)" fill="#1d1d1b"></rect></svg></span>
			<span class="wzb-filter__title">Forschungsgruppen</span>
		</div>
		<ul class="wzb-filter__items wzb-filter__items--publication">
			${this.researchGroupMap.map(group => TagFilterGroup.for(group))}
		</ul>
	</div>
		`;
	}

}

export default ResearchGroups;
