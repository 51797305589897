import {Component} from 'hyperhtml';
import config from '../config';
import {ux} from '../store/UxState';
import {zt} from '../store/ZoteroState';
import {qr} from '../store/QueryState';
import ItemTypeFilterItem from './itemTypeFilterItem';
import ItemTypesFilterItem from './itemTypesFilterItem';

class ItemTypes extends Component {
	constructor(props) {
		super();
		this.props = props;
		zt.subscribe((pr) => {
			this.render();
		});
		qr.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('itemTypes');
	}
	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
		<div class="wzb-filters__filter wzb-filter wzb-filter--publications" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat1">
				<div class="wzb-filter__head" data-wzb-filter-head="">
					<span class="wzb-filter__arrow"><svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 13"><rect x="9" y="9" width="3" height="3" transform="translate(0 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="5" y="5" width="3" height="3" transform="translate(0 13) rotate(-90)" fill="#1d1d1b"></rect><rect x="13" y="5" width="3" height="3" transform="translate(8 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="17" y="1" width="3" height="3" transform="translate(16 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="1" y="1" width="3" height="3" transform="translate(0 5) rotate(-90)" fill="#1d1d1b"></rect></svg></span>
					<span class="wzb-filter__title">Publikationstyp</span>
				</div>
				<ul class="wzb-filter__items wzb-filter__items--publication">
					${zt.props.itemTypes
						.filter(itemType => itemType.id !== 'note' && itemType.id !== 'attachement')
						.filter(itemType => config.mainItemTypes.includes(itemType.id))
						.sort((a, b) => a.de > b.de && 1 || -1)
						.map(
						itemType => {
							const active = qr.props.query.itemTypes && qr.props.query.itemTypes.includes(itemType.id);
							return ItemTypeFilterItem.for(Object.assign(itemType, {active: active}));
						}
						)}
					${ItemTypesFilterItem.for({
							itemTypes: zt.props.itemTypes
							.filter(itemType => itemType.id !== 'note' && itemType.id !== 'attachement')
							.filter(itemType => itemType.count > 0)
							.filter(itemType => !config.mainItemTypes.includes(itemType.id)),
							label: 'Sonstige'
						}
					)}
				</ul>
			</div>

    `;
	}

}

export default ItemTypes;
