import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import config from '../config';

class Reset extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.timer = null;
		qr.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		qr.props.setQuery(Object.assign({}, config.initialQuery));
	}
	render() {
		return this.html`
			<button onclick="${this}" class="wzb-filters__reset">zurücksetzen</button>
		`;
	}

}

export default Reset;
