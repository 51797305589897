import Filter from './filter';

class FilterCheckboxes extends Filter {
	constructor({
					root,
					element,
					headAttribute = 'filterHead',
					checkboxAttribute = 'checkbox',
					openClass = 'open',
					hasCheckedElementClass = 'active',
				}) {
		super({root: root, element: element});
		this.headAttribute = headAttribute;
		this.openClass = openClass;
		this.checkboxAttribute = checkboxAttribute;
		this.hasCheckedElementClass = hasCheckedElementClass;
	}

	prepare() {
		super.prepare();
		this.head = this.element.querySelector(this.dataSelector(this.headAttribute));
		this.listeners.click = this.events.on(this.head, 'click', this.onHeadClick.bind(this));

		this.checkboxes = this.element.querySelectorAll(this.dataSelector(this.checkboxAttribute));
		this.checkboxesNo = this.checkboxes.length;

		this.listeners.checkboxChange = this.events.on(this.element, this.dataSelector(this.checkboxAttribute), 'change', this.onCheckboxChange.bind(this));
	}

	onHeadClick(e) {
		this.classList(this.element).toggle(this.openClass);
	}

	onCheckboxChange(e) {
		this.checkHasCheckedElement();
		this.emitChange();
		//	this.getData();
	}

	getData() {
		const data = [];
		for (let i = 0; i < this.checkboxesNo; i++) {
			if (this.checkboxes[i].checked === true) {
				data.push(this.checkboxes[i].value);
			}
		}

		return data;
	}

	checkHasCheckedElement() {
		let checkedElement = false;
		for (let i = 0; i < this.checkboxesNo; i++) {
			if (this.checkboxes[i].checked === true) {
				checkedElement = true;
				break;
			}
		}
		this.hasCheckedElement = checkedElement;

		if (this.hasCheckedElement) {
			this.classList(this.element).add(this.hasCheckedElementClass);
		} else {
			this.classList(this.element).remove(this.hasCheckedElementClass);
		}
	}
}

export default FilterCheckboxes;
