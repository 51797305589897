import {Component} from 'hyperhtml';
import {zt} from '../store/ZoteroState';
import Item from './item';
import Pagination from './pagination';

class Result extends Component {
	constructor() {
		super();

		this.item = new Item();
		this.pagination = new Pagination();
		this.paginationBottom = new Pagination();

		zt.subscribe((pr) => {
			this.render();
		});

	}
	render() {
		return this.html`
			<div class="wzb-filteredArticles__articles wzb-filteredArticles__articles--paginated">
				<div>
					<div class="wzb-filteredArticles__head">
						${this.pagination}
					</div>

					<ul class="wzb-filteredArticles__articlesList">
						${zt.props.items.filter(item => item.title !== '' ).map(
							item => Item.for(item)
						)}
					</ul>

					<div class="wzb-filteredArticles__footer">
						${this.paginationBottom}
					</div>

				</div>
			</div>
		`;
	}

}

export default Result;
