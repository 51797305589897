import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';

class ItemTypesFilterItem extends Component {
	constructor(props) {
		super();
		this.props = props;
		// label: label of Checkbox
		// itemTypes: array of itemType objects
		qr.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		const query = qr.props.query;
		const currentlyActive = this.isActive();

		// remove all of my itemTypes from Query
		query.itemTypes = query.itemTypes.filter(itemType => !this.props.itemTypes.map(it => it.id).includes(itemType));
		if (!currentlyActive) {
			// when not Active, add all of my itemTypes
			this.props.itemTypes.forEach(itemType => query.itemTypes.push(itemType.id));
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	isActive() {
		return qr.props.query.itemTypes && this.props.itemTypes.some(itemType => qr.props.query.itemTypes.includes(itemType.id));
	}
	render() {
			return this.html`
			<li class="wzb-filter__item">
				<label class="wzb-checkbox">
					<input class="wzb-checkbox__checkbox" onChange="${this}" ?checked=${this.isActive()} type="checkbox" name="checkbox">
					<span class="wzb-checkbox__label">${this.props.label}</span>
					<span class="wzb-checkbox__on"><span data-type="svg"><canvas width="15" height="15"></canvas><svg viewBox="0 0 15 15"  role="img"><use xlink:href="#circle-checked"></use></svg></span></span>
					<span class="wzb-checkbox__off"><span data-type="svg"><canvas width="15" height="15"></canvas><svg viewBox="0 0 15 15" role="img"><use xlink:href="#circle-unchecked"></use></svg></span></span>
				</label>
			</li>
		`;
	}

}

export default ItemTypesFilterItem;
