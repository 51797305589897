import {bind} from 'hyperhtml';
import PageComponent from '../../common/component/page-component';
import App from './app';
import {zt} from './store/ZoteroState';
import {qr} from './store/QueryState';
import {ux} from './store/UxState';

import config from './config';

class Zotero extends PageComponent {

	constructor({
								element,
								root,
								zoteroUrlAttribute = 'zoteroUrl',
								noFilterAttribute = 'noFilter',
								fixAttribute = 'fix',
								hideAttribute = 'hide',
							}) {
		super({root: root, element: element});
		this.zoteroUrlAttribute = zoteroUrlAttribute;
		this.noFilterAttribute = noFilterAttribute;
		this.fixAttribute = fixAttribute;
		this.hideAttribute = hideAttribute;
	}

	prepare() {
		this.popped = false;
		this.zoteroUrl = this.dataAttr(this.element).get(this.zoteroUrlAttribute);

		this.noFilter = this.dataAttr(this.element).get(this.noFilterAttribute);
		ux.props.setNoFilter(this.noFilter);

		this.fix = this.dataAttr(this.element).get(this.fixAttribute);
		if (this.fix) {
      console.log('fix', this.fix);
			const fixObj = this.queryFromUrl(this.fix);
			if (fixObj.hasOwnProperty('page')) {
				delete fixObj.page;
			}
      console.log('fixObj', fixObj);
			qr.props.setFix(fixObj);
			ux.props.setHide(Object.keys(fixObj));
		}

		this.renderFn = bind(this.element);
		this.initContent();
		this.render();

		qr.props.setQuery(Object.assign({}, config.initialQuery, this.queryFromUrl(window.location.hash)));
		qr.subscribe((pr) => {
			this.fetchItems();
			if (!this.popped) {
				this.pushHistory();
			}
		});
		window.addEventListener('popstate', e => this.popHistory(e));
	}

	render() {
		// eslint-disable-next-line no-unused-expressions
		this.renderFn`${new App()}`;
	}

	queryFromUrl(fragment) {
		const urlParams = new URLSearchParams(fragment.replaceAll('?', ''));

		const query = {
			page: 1
		};
		for(const entry of urlParams.entries()) {
			const key = entry[0];
			const value = entry[1];
			if (key === 'page') {
				query.page = value;
			}
			if (key === 'itemTypes') {
				query.itemTypes = value.split(',');
			}
			if (key === 'reportTypes') {
				query.reportTypes = value.split(',');
			}
			if (key === 'tags') {
				query.tags = value.split(',');
			}
      if (key === 'excludeTags') {
        query.excludeTags = value.split(',');
      }
			if (key === 'start') {
				query.start = value;
			}
			if (key === 'end') {
				query.end = value;
			}
			if (key === 'reviewed') {
				query.reviewed = value==='true';
			}
			if (key === 'search') {
				query.search = value;
			}
			if (key === 'searchCreator') {
				query.searchCreator = value;
			}
			if (key === 'sort') {
				query.sort = value;
			}
		}
		return query;
	}

	initContent() {
		this.fetchTags();
		this.fetchCreators();
		this.fetchItemTypes();
	}

	fetchItems() {
		const url = new URL(this.zoteroUrl + '/item');
		// eslint-disable-next-line no-return-assign
		// const query = Object.entries(qr.props.query).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {});

		const apiParams = new URLSearchParams();
		// convert page to limit/offset
		apiParams.set('limit', '' + 20);
		apiParams.set('offset', '' + (20 * (qr.props.query.page - 1)));

    console.log('query', qr.props.query);

    if (qr.props.query.itemTypes && qr.props.query.itemTypes.length > 0) {
			apiParams.set('itemTypes', qr.props.query.itemTypes.join(','));
		}
		if (qr.props.query.reportTypes && qr.props.query.reportTypes.length > 0) {
			apiParams.set('reportTypes', qr.props.query.reportTypes.join(','));
		}
		if (qr.props.query.tags && qr.props.query.tags.length > 0) {
			apiParams.set('tags', qr.props.query.tags.join(','));
		}
    if (qr.props.query.excludeTags && qr.props.query.excludeTags.length > 0) {
      apiParams.set('excludeTags', qr.props.query.excludeTags.join(','));
    }
		if (qr.props.query.sort) {
			apiParams.set('sort', qr.props.query.sort);
		}
		if (qr.props.query.search) {
			apiParams.set('search', qr.props.query.search);
		}
		if (qr.props.query.searchCreator) {
			apiParams.set('searchCreator', qr.props.query.searchCreator);
		}
		if (qr.props.query.start) {
			apiParams.set('start', qr.props.query.start);
		}
		if (qr.props.query.end) {
			apiParams.set('end', qr.props.query.end);
		}
		if (qr.props.query.reviewed) {
			apiParams.set('reviewed', qr.props.query.reviewed?'true':'false');
		}

		url.search = new URLSearchParams(apiParams).toString();

		fetch(url.toString()).then(b => b.json()).then(data => {
			zt.props.setItems(data.data.items);
			zt.props.setTotal(data.data.total);
		});
	}
	isFixed(property) {
		return qr.props.fix && qr.props.fix.hasOwnProperty(property);
	}
	pushHistory() {
		const locParams = new URLSearchParams();

		// convert query to state
		if (qr.props.query.page && !this.isFixed('page')) {
			locParams.set('page', qr.props.query.page);
		}
		if (qr.props.query.itemTypes && qr.props.query.itemTypes.length > 0 && !this.isFixed('itemTypes')) {
			locParams.set('itemTypes', qr.props.query.itemTypes);
		}
		if (qr.props.query.reportTypes && qr.props.query.reportTypes.length > 0 && !this.isFixed('reportTypes')) {
			locParams.set('reportTypes', qr.props.query.reportTypes);
		}
		if (qr.props.query.tags && qr.props.query.tags.length > 0 && !this.isFixed('tags')) {
			locParams.set('tags', qr.props.query.tags);
		}
    if (qr.props.query.excludeTags && qr.props.query.excludeTags.length > 0 && !this.isFixed('excludeTags')) {
      locParams.set('excludeTags', qr.props.query.excludeTags);
    }
		if (qr.props.query.sort && !this.isFixed('sort')) {
			locParams.set('sort', qr.props.query.sort);
		}
		if (qr.props.query.search && !this.isFixed('search')) {
			locParams.set('search', qr.props.query.search);
		}
		if (qr.props.query.searchCreator && !this.isFixed('searchCreator')) {
			locParams.set('searchCreator', qr.props.query.searchCreator);
		}
		if (qr.props.query.start && !this.isFixed('start')) {
			locParams.set('start', qr.props.query.start);
		}
		if (qr.props.query.end && !this.isFixed('end')) {
			locParams.set('end', qr.props.query.end);
		}
		if (qr.props.query.reviewed && !this.isFixed('reviewed')) {
			locParams.set('reviewed', qr.props.query.reviewed);
		}
		this.popped = false;
		history.pushState(Object.fromEntries(locParams), '', '#' + locParams.toString());
	}

	popHistory(e) {
		// convert state to query

		// TODO, get default query and set values from state
		const locParams = e.state;
		if (locParams) {
			if (locParams.itemTypes) {
				locParams.itemTypes = locParams.itemTypes.split(',');
			} else {
				locParams.itemTypes = [];
			}
			if (locParams.reportTypes) {
				locParams.reportTypes = locParams.reportTypes.split(',');
			} else {
				locParams.reportTypes = [];
			}
			if (locParams.tags) {
				locParams.tags = locParams.tags.split(',');
			} else {
				locParams.tags = [];
			}
      if (locParams.excludeTags) {
        locParams.excludeTags = locParams.excludeTags.split(',');
      } else {
        locParams.excludeTags = [];
      }
			if (locParams.reviewed) {
				locParams.reviewed = locParams.reviewed === true;
			} else {
				locParams.reviewed = false;
			}

			this.popped = true;
			qr.props.setQuery(locParams);
		}
	}

	fetchTags(query) {
		const url = new URL(this.zoteroUrl + '/tag');
		fetch(url.toString()).then(b => b.json()).then(data => {
			zt.props.setTags(data.data);
			console.log('tags', zt.props.tags);
		});
	}

	fetchItemTypes(query) {
		const url = new URL(this.zoteroUrl + '/itemType');
		fetch(url.toString()).then(b => b.json()).then(data => {
			zt.props.setItemTypes(data.data);
			console.log('itemTypes', zt.props.itemTypes);
		});
	}

	fetchCreators(query) {
		const url = new URL(this.zoteroUrl + '/creator');
		fetch(url.toString()).then(b => b.json()).then(data => {
			zt.props.setCreators(data.data);
			console.log('creators', zt.props.creators);
		});
	}

}

export default Zotero;
