import {Component} from 'hyperhtml';
import {zt} from '../store/ZoteroState';
import {ux} from '../store/UxState';
import TagFilterItem from './tagFilterItem';
import ItemTypes from './itemTypes';
import ItemTypeFilterItem from './itemTypeFilterItem';
import Search from './search';
import SearchCreator from './searchCreator';
import Reset from './reset';
import Interval from './interval';
import ReviewedFilterItem from './reviewedFilterItem';
import ResearchGroups from './researchGroups';
import Sort from './sort';
import SpecialFilterItem from './specialFilterItem';

class Filter extends Component {
	constructor() {
		super();

		this.tagFilterItem = new TagFilterItem();
		this.itemTypeFilterItem = new ItemTypeFilterItem();
		this.search = new Search();
		this.searchCreator = new SearchCreator();
		this.reset = new Reset();
		this.interval = new Interval();
		this.researchGroups = new ResearchGroups();
		this.itemTypes = new ItemTypes();
		this.sort = new Sort();
		this.reviewedFilterItem = new ReviewedFilterItem({label: 'Begutachtete Publikationen'});

		zt.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});

	}
	render() {
		return ux.props.noFilter?
			this.html`<div class="wzb-filteredArticles__filters wzb-filters wzb-filters--publications">

			</div>
			`:
			this.html`<nav class="wzb-filteredArticles__filters wzb-filters wzb-filters--publications">
        ${this.search}
        ${this.searchCreator}
        <br>
				${this.itemTypes}
				${this.interval}
				${this.sort}

        <div class="wzb-filters__filter wzb-filter">&nbsp;</div>
        <div class="wzb-filters__filter wzb-filter">
          ${SpecialFilterItem.for({tag: 'Weizenbaum', label: 'Weizenbaum-Publikationen'})}
        </div>
        ${this.reviewedFilterItem}
        <br>

				${this.reset}

		  </nav>
			`;
	}

}

export default Filter;
