import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import {ux} from '../store/UxState';

class Interval extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.timer = null;
		qr.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		const value = e.target.value;
		clearTimeout(this.timer);
		if (e.target.id === 'start') {
			this.timer = setTimeout(() => this.applyStart(value), 500);
		} else {
			this.timer = setTimeout(() => this.applyEnd(value), 500);
		}
	}
	applyStart(startValue) {
		const query = qr.props.query;
		query.start = startValue;
		if (query.start !== '' && query.start < '2015-01-01') {
			query.start = '2015-01-01';
		}
		if (query.start !== '' && query.end < query.start) {
			query.end = query.start;
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	applyEnd(endValue) {
		const query = qr.props.query;
		query.end = endValue;
		if (query.end !== '' && query.end < '2015-01-01') {
			query.end = '2015-01-01';
		}
		if (query.start !== '' && query.start > query.end) {
			query.end = query.start;
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	isHidden() {
		return ux.props.hide && (ux.props.hide.includes('start') || ux.props.hide.includes('end'));
	}

	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
		<div class="wzb-filters__filter wzb-filter wzb-filter--publications" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat4">
			<div class="wzb-filter__head" data-wzb-filter-head="">
				<span class="wzb-filter__arrow"><svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 13"><rect x="9" y="9" width="3" height="3" transform="translate(0 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="5" y="5" width="3" height="3" transform="translate(0 13) rotate(-90)" fill="#1d1d1b"></rect><rect x="13" y="5" width="3" height="3" transform="translate(8 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="17" y="1" width="3" height="3" transform="translate(16 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="1" y="1" width="3" height="3" transform="translate(0 5) rotate(-90)" fill="#1d1d1b"></rect></svg></span>
				<span class="wzb-filter__title">Zeitraum</span>
			</div>
			<div class="wzb-filter__items">
				<form class="wzb-filters__filterInterval">
					<label class="wzb-inputField wzb-inputField--date" for="start">
						<span class="wzb-inputField__label wzb-inputField__label--date">von</span>
						<input class="wzb-inputField__input wzb-inputField__input--date" onChange="${this}" id="start" name="start" type="date" value="${qr.props.query.start}">
					</label>
					<label class="wzb-inputField wzb-inputField--date" for="start">
						<span class="wzb-inputField__label wzb-inputField__label--date">bis</span>
						<input class="wzb-inputField__input wzb-inputField__input--date" onChange="${this}" id="end" name="end" type="date" value="${qr.props.query.end}">
					</label>
				</form>
	  </div>
	</div>
		`;
	}

}

export default Interval;
