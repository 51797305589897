import {Component} from 'hyperhtml';
import Filter from './components/filter';
import Result from './components/result';
import {zt} from './store/ZoteroState';

class App extends Component {
	constructor() {
		super();

		this.result = new Result();
		this.filter = new Filter();

		zt.subscribe((pr) => {
			//this.render();
		});

	}

	render() {
		return this.html`
			<section class="wzb-section wzb-section--overflow" id="wzb-zotero">
				<div class="wzb-filteredArticles">
					${this.filter}
					${this.result}
				</div>
			</section>
		`;
	}
}

export default App;
