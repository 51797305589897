import {compose, withState} from 'proppy';

const UxState = compose(
	withState('noFilter', 'setNoFilter', false),
	withState('hide', 'setHide', [])

);

const ux = UxState();

export {
	ux
};
