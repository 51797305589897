import {Component} from 'hyperhtml';
import {ux} from '../store/UxState';
import SortOption from './sortOption';
import config from '../config';

class Sort extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.sortOptionsMap = config.sortOptionsMap;

		ux.subscribe((pr) => {
			this.render();
		});
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('sort');
	}
	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
		<div class="wzb-filters__filter wzb-filter wzb-filter--publications" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat3">
			<div class="wzb-filter__head" data-wzb-filter-head="">
				<span class="wzb-filter__arrow"><svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 13"><rect x="9" y="9" width="3" height="3" transform="translate(0 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="5" y="5" width="3" height="3" transform="translate(0 13) rotate(-90)" fill="#1d1d1b"></rect><rect x="13" y="5" width="3" height="3" transform="translate(8 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="17" y="1" width="3" height="3" transform="translate(16 21) rotate(-90)" fill="#1d1d1b"></rect><rect x="1" y="1" width="3" height="3" transform="translate(0 5) rotate(-90)" fill="#1d1d1b"></rect></svg></span>
				<span class="wzb-filter__title">Sortieren nach</span>
			</div>
			<ul class="wzb-filter__items wzb-filter__items--publication">
				${this.sortOptionsMap.map(
				  sortOption => SortOption.for(sortOption)
				)}
			</ul>
		</div>
		`;
	}

}

export default Sort;
